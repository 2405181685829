import React, { ReactNode } from 'react';
import { useMantineTheme, Group, Popover, Flex, Text, Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons';

interface TextWithPopoverProps {
  cellText: string | number | null;
  popoverContent: ReactNode;
  children?: ReactNode;
}

export const TextWithPopover: React.FC<TextWithPopoverProps> = ({
  cellText,
  popoverContent,
  children,
}) => {
  const theme = useMantineTheme();
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Group spacing={'xs'} position={'right'} align={'center'}>
      <Popover position="right" withArrow shadow="md" opened={opened}>
        <Popover.Target>
          <Box onMouseEnter={open} onMouseLeave={close}>
            <Text size={'sm'}>{cellText}</Text>
          </Box>
        </Popover.Target>
        <Popover.Dropdown p={0} sx={{ pointerEvents: 'none' }}>
          {popoverContent}
        </Popover.Dropdown>
        {children}
      </Popover>
    </Group>
  );
};
