import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Title,
  Group,
  Loader,
  Stack,
  Text,
  Flex,
  Paper,
} from '@mantine/core';
import {
  IconCalendar,
  IconReportSearch,
  IconUser,
} from '@tabler/icons';
import { Application } from '../../../models/Application';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { FormViewer } from '../../../components/formbuilder/FormViewer';
import { isMissing } from 'utilitype';
import { ViewNavigation } from '../../../components/navigation/ViewNavigation';
import { applicationViewStyles } from './applicationViewStyles';
import { FetchForReviewType } from '../../../models/PagedResult';
import { ReviewInformation } from '../../common/application/ReviewInformation';
import Formatter from '../../../utils/formatter';
import { ApplicantTypes } from '../../period/ApplicationPeriodView';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { ApplicationToolBar } from './ApplicationToolbar';

export const ApplicationView: React.FC = () => {
  const { selectedOrganisation } = useSession();
  const { classes, theme } = applicationViewStyles();
  const { applicationId } = useParams();
  const searchParams = useLocation().search;
  const api = useApi();

  const [application, setApplication] = useState<Application>();

  const filterOnPeriod = new URLSearchParams(searchParams).get('filterOnPeriod');
  const filterOnType = new URLSearchParams(searchParams).get('filterOnType');

  const filterPeriod: boolean | undefined = filterOnPeriod ? filterOnPeriod === 'true' : undefined;
  const filterType: FetchForReviewType | undefined = filterOnType
    ? Number(filterOnType)
    : undefined;

  const fetchApplication = async () => {
    if (selectedOrganisation && applicationId) {
      const applicationResponse = await api.getApplication(
        selectedOrganisation.id,
        applicationId,
        filterPeriod,
        filterType,
      );

      setApplication(applicationResponse);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchApplication();
    };

    fetchData();
  }, [selectedOrganisation, applicationId]);

  if (isMissing(selectedOrganisation) || isMissing(application)) {
    return (
      <Group position={'center'} align={'top'} pt={40} style={{ flex: 1 }}>
        <Loader size={'xl'} />
      </Group>
    );
  }

  return (
    <GrantMeContainer scrollable={false}>
      <GrantMeTitleContainer>
        <Group
          position={'apart'}
          noWrap
          style={{ flex: '1 1 auto', minWidth: 0, overflow: 'hidden' }}>
          <Stack spacing={'xs'} style={{ overflow: 'hidden' }}>
            <Title
              order={3}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>{`${application.number} ${application.title}`}</Title>
            <Group>
              <Group spacing={4} noWrap>
                <IconCalendar size={18} color={theme.colors.gray[6]} />
                <Text weight={600} size={'sm'} color={'dimmed'} style={{ whiteSpace: 'nowrap' }}>
                  {Formatter.formatDateString(application.submittedUtc)}
                </Text>
              </Group>
              <Group spacing={4} noWrap>
                <IconUser size={18} color={theme.colors.gray[6]} />
                <Text weight={600} size={'sm'} color={'dimmed'} style={{ whiteSpace: 'nowrap' }}>
                  {ApplicantTypes[application.applicantType]}
                </Text>
              </Group>
              <Group spacing={4} noWrap style={{ minWidth: 0, flex: 1 }}>
                <IconReportSearch size={18} color={theme.colors.gray[6]} />
                <Text
                  weight={600}
                  size={'sm'}
                  color={'dimmed'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                  {application.periodTitle}
                </Text>
              </Group>
            </Group>
          </Stack>
          <ViewNavigation
            application={application}
            context={filterType}
            filterPeriod={filterPeriod}
          />
        </Group>
      </GrantMeTitleContainer>

      {(filterType === FetchForReviewType.ReviewAll ||
        filterType === FetchForReviewType.ReviewTodo ||
        filterType === FetchForReviewType.ReviewCompleted ||
        filterType === FetchForReviewType.ReviewDeleted) && (
        <ReviewInformation
          filterOnId={application.periodId}
          triggerOnChange={application.reviewStatus}
        />
      )}

      <Flex direction={'row'} style={{ flex: 1, overflow: 'hidden' }}>
        <Flex direction={'row'} style={{ flex: 2, overflow: 'hidden' }}>
          <div className={classes.scrollContainer}>
            <Paper className={classes.formContainer}>
              <FormViewer json={JSON.stringify(application.applicationJson)} showPageLabels />
            </Paper>
          </div>

          {application && (
            <ApplicationToolBar
              application={application}
              context={filterType}
              onChanged={async () => {
                await fetchApplication();
              }}
            />
          )}
        </Flex>
      </Flex>
    </GrantMeContainer>
  );
};
