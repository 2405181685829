export const emailRegExp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
export const identityNumberPerson = new RegExp('^(19|20)[0-9]{10}$');
import luhn from 'luhn';

export default class Validator {

  static validateEmail(value: string) {
    return emailRegExp.test(value);
  }

  static validateIdentityNumberPerson(value: string) {
    return identityNumberPerson.test(value);
  }

  static validateIdentityNumberSwedish(checkValue: string) {
    let value = checkValue.trim().replace('-', '');

    const number = Number(value);
    if (isNaN(number)) {
      return false;
    }

    if (value.length === 12) {
      value = value.substring(2, 12);
    }

    return luhn.validate(value);
  }

  static validateBGNumber(value: string) {
    const normalized = value.toString().replace(/\s+/g, '').replace(/-/g, '');

    if (normalized.length < 7 || normalized.length > 8) {
      return false;
    }

    if (isNaN(+normalized)) { 
      return false;
    }

    return luhn.validate(normalized);
  }

  static validatePGNumber(value: string) {
    const normalized = value.toString().replace(/\s+/g, '').replace(/-/g, '');

    if (normalized.length < 2 || normalized.length > 8) {
      return false;
    }

    if (isNaN(+normalized)) { 
      return false;
    }

    return luhn.validate(normalized);
  }
}