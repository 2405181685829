import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Group, Stack, Title, useMantineTheme, Text, createStyles } from '@mantine/core';
import { useSession } from '../../../hooks/useSession';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { ApplicationItem } from '../../../models/ApplicationItem';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { ReviewApplicationViewColumns } from './ReviewApplicationListColumns';
import { setColorsByDistinctId } from '../../../components/LiitGrid/SetColorsByDistinctId';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { PagedResult, FetchForReviewType } from '../../../models/PagedResult';
import { isMissing } from 'utilitype';
import { useSessionStorage } from '@mantine/hooks';
import { IconCoffee, TablerIcon } from '@tabler/icons';
import { ReviewInformation } from '../../common/application/ReviewInformation';
import { LiitDropdown, LiitDropdownMenuItem } from '../../../components/LiitDropdown/LiitDropdown';
import { t } from 'ttag';
import { BORDER_COLOR } from '../../../App';
import { ReviewApplicationListPrintButton } from './ReviewApplicationListPrintButton';

interface ReviewApplicationListProps {
  reviewType: FetchForReviewType;
}

export const styles = createStyles((theme) => ({
  filterGroup: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
}));

export const ReviewApplicationList: React.FC<ReviewApplicationListProps> = ({ reviewType }) => {
  const [reviewablePeriods, setReviewablePeriods] = useState<LiitDropdownMenuItem[] | null>(null);
  const [applications, setApplications] = useState<PagedResult<ApplicationItem> | undefined>(undefined);
  const [rType, setRType] = useState<FetchForReviewType>(reviewType);
  const navigate = useNavigate();
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const [isFetching, setIsFetching] = useState(true);
  const theme = useMantineTheme();
  const { classes } = styles();
  const [reviewFilterType, setReviewFilterType] = useSessionStorage<FetchForReviewType | undefined>(
    { key: 'review-filter-type', defaultValue: FetchForReviewType.ReviewTodo },
  );
  const [reviewFilterPeriod, setReviewFilterPeriod] = useSessionStorage<string | null | undefined>({
    key: 'review-filter-period',
    defaultValue: undefined,
  });

  const setColorsbyFormId = (items: ApplicationItem[], periods: LiitDropdownMenuItem[]) => {
    items.forEach((application) => {
      const { formId } = application;
      const colorItem = periods.find((p) => p.id === formId);
      application.categoryColor = {
        color: colorItem?.color ?? 'white',
        inverted: colorItem?.inverted ?? 'black',
      };
    });
  };

  useEffect(() => {
    const fetchPeriods = async () => {
      if (isMissing(selectedOrganisation)) {
        return;
      }

      const reviewable = await api.getReviewablePeriods(selectedOrganisation.id);

      const colors = setColorsByDistinctId(
        theme,
        reviewable.map((r) => r.periodId),
      );

      setReviewablePeriods(
        reviewable.map((r) => {
          return {
            id: r.periodId,
            text: r.title,
            color: colors[r.periodId].color,
            inverted: colors[r.periodId].inverted,
          };
        }),
      );
    };

    fetchPeriods();
  }, [selectedOrganisation]);

  const fetchApplications = async (page: number, type?: FetchForReviewType) => {
    if (!selectedOrganisation || reviewFilterPeriod === undefined || reviewablePeriods === null) {
      return;
    }

    if (
      reviewFilterPeriod !== null &&
      !reviewablePeriods.some((rp) => rp.id === reviewFilterPeriod)
    ) {
      setReviewFilterPeriod(null);
      return;
    }

    setIsFetching(true);

    if (isMissing(type) || type === FetchForReviewType.ReviewTodo) {
      const fetchedApplications = await api.getApplicationsForReview(
        selectedOrganisation.id,
        reviewType,
        reviewFilterPeriod,
        page,
      );

      setColorsbyFormId(fetchedApplications.data, reviewablePeriods);
      setApplications(fetchedApplications);
    }

    setIsFetching(false);
  };

  useEffect(() => {
    setRType(reviewType);
  }, [applications]);

  useEffect(() => {
    if (selectedOrganisation && reviewFilterPeriod !== undefined) {
      fetchApplications(1);
    }
  }, [selectedOrganisation, reviewFilterPeriod, reviewablePeriods]);

  useEffect(() => {
    if (isFetching && selectedOrganisation && reviewFilterPeriod !== undefined) {
      fetchApplications(1);
    }
  }, [isFetching]);

  useEffect(() => {
    setIsFetching(true);
  }, [reviewType]);

  const getPeriodName = () => {
    if (reviewablePeriods && reviewFilterPeriod) {
      return reviewablePeriods.find((x) => x.id === reviewFilterPeriod)?.text;
    }
    return null;
  };

  const pageInfo: Pick<
  Record<
  FetchForReviewType,
  { title: string | undefined; text: string | undefined; icon: TablerIcon | undefined }
  >,
  | FetchForReviewType.ReviewTodo
  | FetchForReviewType.ReviewCompleted
  | FetchForReviewType.ReviewDeleted
  | FetchForReviewType.ReviewAll
  > = {
    [FetchForReviewType.ReviewTodo]: {
      title: t`Ej granskade ansökningar`,
      text: t`Bra jobbat! Alla ansökningar är granskade. Dags för en kaffe.`,
      icon: IconCoffee,
    },
    [FetchForReviewType.ReviewCompleted]: {
      title: t`Granskade ansökningar`,
      text: t`För att granska ansökningar välj "Ej granskade" i menyn.`,
      icon: undefined,
    },
    [FetchForReviewType.ReviewDeleted]: {
      title: t`Borttagna ansökningar`,
      text: t`Det finns inga borttagna ansökningar.`,
      icon: undefined,
    },
    [FetchForReviewType.ReviewAll]: { title: 'Alla ansökningar', text: undefined, icon: undefined },
  };

  if (reviewFilterPeriod === undefined || reviewFilterType === undefined) {
    return null;
  }

  return (
    <GrantMeContainer takeAllSpace={true} scrollable={false} style={{ overflow: 'hidden' }}>
      <Stack spacing={0} style={{ flex: 1, overflow: 'hidden' }}>
        <GrantMeTitleContainer>
          <Title order={3}>{pageInfo[reviewType].title}</Title>
          <ReviewApplicationListPrintButton
            title={pageInfo[reviewType].title}
            periodName={getPeriodName()}
            applications={applications?.data}
          />
        </GrantMeTitleContainer>
        <Group pl={'xl'} h={36} className={classes.filterGroup} spacing={8}>
          <Text size={'sm'} weight={600}>{t`Filter:`}</Text>
          <LiitDropdown
            emptyText={t`Alla perioder`}
            menuItems={reviewablePeriods}
            onChange={(periodId) => {
              setReviewFilterPeriod(periodId);
            }}
            selectedId={reviewFilterPeriod}
            showColorBox={true}
          />
        </Group>
        <ReviewInformation filterOnId={reviewFilterPeriod} />
        <Flex style={{ flex: 1, overflow: 'auto' }}>
          <LiitGrid
            data={applications}
            columnInfo={ReviewApplicationViewColumns()}
            emptyText={pageInfo[rType].text}
            emptyIcon={pageInfo[rType].icon}
            onRowClick={(id: string) => {
              const filterOnType = `filterOnType=${reviewType}`;
              const filterOnPeriod = reviewFilterPeriod ? 'filterOnPeriod=true' : '';
              const filters = [filterOnType, filterOnPeriod].filter(Boolean).join('&');
              const path = `${id}` + (filters ? `?${filters}` : '');

              navigate(path);
            }}
            fetchingData={isFetching}
            onPageChange={(page) => fetchApplications(page, FetchForReviewType.ReviewTodo)}
            isPrintable={true}
          />
        </Flex>
      </Stack>
    </GrantMeContainer>
  );
};
