import { IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import { RenderAmountCell } from '../../common/grid-columns/RenderAmountCell';
import { RenderApplicantCell } from '../../common/grid-columns/RenderApplicantCell';
import { RenderCategoryCell } from '../../common/grid-columns/RenderCategoryCell';
import { RenderReviewStatusCell } from '../../common/grid-columns/RenderReviewStatusCell';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ReviewApplicationViewColumns = (): Array<IDisplayColumn> => [
  {
    displayName: '',
    propertyName: 'categoryColor',
    propertyType: PropertyType.Text,
    renderCell: RenderCategoryCell,
    columnStyles: { ...columnStyles, padding: 0 },
  },
  {
    displayName: 'Diarienr',
    propertyName: 'number',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, paddingLeft: '16px', paddingRight: 0 },
  },
  {
    displayName: 'Inkom',
    propertyName: 'submittedUtc',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Sökande',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicantCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    hideFromPrint: true,
  },
  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { cursor: 'pointer', userSelect: 'none' },
    maxLength: 100,
  },
  {
    displayName: 'Sökt belopp',
    propertyName: 'requestedAmount',
    propertyType: PropertyType.Currency,
    renderCell: RenderAmountCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Bedömning',
    propertyName: 'reviewStatus',
    propertyType: PropertyType.Text,
    renderCell: RenderReviewStatusCell,
    columnStyles: { ...columnStyles, padding: 0, paddingLeft: '16px', paddingRight: '8px' },
  },
];
