import { Text, Popover, Group, Flex, Table } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons';
import { ReactNode } from 'react';
import { t } from 'ttag';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { TextWithPopover } from './TextWithPopover';
import Formatter from '../../../utils/formatter';

export const RenderAverageCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { rowValues, theme } = rowInformation;
  const { reviewTotalAverage, reviewScoreAverages, excludedCriterias, reviewCompletionStatus } =
    rowValues;

  const hasReviews = reviewScoreAverages.length > 0;

  const getAverageToolTip = () => {
    if (!hasReviews) {
      const biasedCount = reviewCompletionStatus.reduce((a, b) => {
        if (b.isBiased) {
          return a + 1;
        }
        return a;
      }, 0);

      if (biasedCount > 0) {
        return (
          <Text p={16} size={'xs'} weight={500}>
            {t`${biasedCount} jäv och inga granskningar gjorda`}
          </Text>
        );
      }
      return (
        <Text p={16} size={'xs'} weight={500}>
          {t`Inga granskningar gjorda på denna ansökan`}
        </Text>
      );
    }

    const hasExclusions = excludedCriterias && excludedCriterias.length > 0;

    const included = hasExclusions
      ? reviewScoreAverages.filter(
        (scoreAverage) => !excludedCriterias.some((ex) => ex === scoreAverage.criteriaId),
      )
      : reviewScoreAverages;
    const excluded = hasExclusions
      ? reviewScoreAverages.filter((scoreAverage) =>
        excludedCriterias.some((ex) => ex === scoreAverage.criteriaId),
      )
      : [];

    return (
      <Table p={0} cellSpacing={'xs'}>
        <thead>
          <tr>
            <th style={{ paddingBottom: '2px', borderBottom: `1px solid ${theme.colors.gray[3]}` }}>
              <Text size={'xs'}>{t`Kriterie`}</Text>
            </th>
            <th style={{ paddingBottom: '2px', borderBottom: `1px solid ${theme.colors.gray[3]}` }}>
              <Text size={'xs'}>{t`Medel`}</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {included.map((c) => (
            <tr key={`r_${c.criteriaTitle}`}>
              <td style={{ paddingTop: 2, paddingBottom: 2, border: 0 }}>
                <Text p={0} size={'xs'} weight={500}>
                  {c.criteriaTitle}
                </Text>
              </td>
              <td style={{ textAlign: 'end', paddingTop: 2, paddingBottom: 2, border: 0 }}>
                <Text p={0} size={'xs'} weight={'bold'}>
                  {Formatter.formatNumber(c.average) ?? '-'}
                </Text>
              </td>
            </tr>
          ))}
          {excluded.length > 0 && (
            <>
            <tr>
              <td colSpan={2} style={{ paddingBottom: '2px', border: 0,  borderBottom: `1px solid ${theme.colors.gray[3]}` }}>
                <Text size={'xs'} weight={'bold'} color={theme.colors[theme.primaryColor][7]}>
                  {t`Exkluderad kriterier`}
                </Text>
              </td>
            </tr>
            {excluded.length > 0 && excluded.map((c) => 
              <tr key={`r_excluded_${c.criteriaId}`}>
                <td style={{ paddingTop: 2, paddingBottom: 2, border: 0 }}>
                <Text p={0} size={'xs'} weight={500} color={'dimmed'}>
                  {c.criteriaTitle}
                </Text>
              </td>
              <td style={{ textAlign: 'end', paddingTop: 2, paddingBottom: 2, border: 0 }}>
                <Text p={0} size={'xs'} weight={'bold'} color={'dimmed'}>
                {Formatter.formatNumber(c.average) ?? '-'}
                </Text>
              </td>
              </tr>)}  
            </>
          )}
        </tbody>
      </Table>
    );
  };

  const roundedTotal = reviewTotalAverage
    ? Formatter.formatNumber(Math.round((reviewTotalAverage + Number.EPSILON) * 100) / 100)
    : null;


  return (
    <TextWithPopover cellText={roundedTotal ?? '-'} popoverContent={getAverageToolTip()} />
  );
};
