import {
  Button,
  Card,
  Group,
  Stack,
  Title,
  useMantineTheme,
  Text,
  SimpleGrid,
  Flex,
  Tabs,
  createStyles,
} from '@mantine/core';
import { IconPrinter } from '@tabler/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { BORDER_COLOR } from '../../../App';
import { BackButton } from '../../../components/BackButton';
import { LiitDropdown, LiitDropdownMenuItem } from '../../../components/LiitDropdown/LiitDropdown';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { CONTAINER_PADDING_WIDTH } from '../../../layout/Layout';
import { Routes } from '../../../layout/Routes';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { FetchForReviewType } from '../../../models/PagedResult';
import Formatter from '../../../utils/formatter';
import { DecisionListPrintButton } from '../../application-approval/DecisionListPrintButton';
import {
  ArchiveApplicationListColumns,
  ArchiveApplicationRequisitionListColumns,
} from './ArchiveApplicationListColumns';
import { ArchiveProtocolPrint } from './ArchiveProtocolPrint';

export const styles = createStyles((theme) => ({
  bottomBorder: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
}));

export const ArchiveApplicationList: React.FC = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<any>();
  const [applications, setApplications] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [decisionId, setDecisionId] = useState<string | null>(null);
  const { selectedOrganisation } = useSession();
  const { applicationPeriodId } = useParams();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const api = useApi();
  const { classes } = styles();

  const refreshArchivedApplicationPeriod = async () => {
    const periodResult = await api.getArchivedApplicationPeriod(
      selectedOrganisation!.id,
      applicationPeriodId!,
    );
    setSelectedPeriod(periodResult);

    if (periodResult) {
      if (periodResult.decisions.length === 1) {
        setDecisionId(periodResult.decisions[0].id);
      }
    }
  };

  const refreshArchivedApplications = async () => {
    const applicationsResult = await api.getArchivedApplications(
      selectedOrganisation!.id,
      applicationPeriodId!,
    );

    setApplications(applicationsResult);
  };

  const getFilteredApplications = () => {
    if (decisionId) {
      return applications.filter((x) => x.decisionId === decisionId);
    } else {
      return applications;
    }
  };

  // const downloadPaymentFile = async () => {
  //   if (selectedOrganisation && selectedPeriod) {
  //     const response = await api.downloadPaymentsFile(selectedOrganisation.id, {
  //       applicationPeriodId: selectedPeriod.id,
  //       applicationIds: checkedRows,
  //     });

  //     if (response.ok) {
  //       const buffer = await response.arrayBuffer();
  //       const url = window.URL.createObjectURL(new Blob([buffer]));
  //       await download(url, 'payments.xml');
  //     } else {
  //       await handleError(response);
  //     }
  //   }
  // };

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      await refreshArchivedApplications();
      await refreshArchivedApplicationPeriod();

      setIsFetching(false);
    };

    if (selectedOrganisation && applicationPeriodId) {
      fetchData();
    }
  }, [selectedOrganisation]);

  const getPeriodDetails = (period: any) => {
    return (
      <Card key={period.id} style={{ borderBottom: `1px solid ${theme.colors.gray[4]}` }}>
        <SimpleGrid cols={5} pl={CONTAINER_PADDING_WIDTH} pr={CONTAINER_PADDING_WIDTH}>
          <div>
            <Text color="dimmed" transform="uppercase" weight={700} size="xs">
              Inkomna ansökningar
            </Text>
            <Text weight={700} size="lg">
              {period.numberOfApprovedApplications + period.numberOfDeclinedApplications} st
            </Text>
          </div>
          <div>
            <Text color="dimmed" transform="uppercase" weight={700} size="xs">
              Beviljade ansökningar
            </Text>
            <Text weight={700} size="lg">
              {period.numberOfApprovedApplications} st
            </Text>
          </div>
          <div>
            <Text color="dimmed" transform="uppercase" weight={700} size="xs">
              Nekade ansökningar
            </Text>
            <Text weight={700} size="lg">
              {period.numberOfDeclinedApplications} st
            </Text>
          </div>
          <Flex align={'flex-end'} direction="column">
            <Text color="dimmed" transform="uppercase" weight={700} size="xs">
              Beviljat belopp
            </Text>
            <Text weight={700} size="lg">
              {Formatter.formatCurrency(period.grantedAmount)}
            </Text>
          </Flex>
          <Flex align={'flex-end'} direction="column">
            <Text color="dimmed" transform="uppercase" weight={700} size="xs">
              Budget
            </Text>
            <Text weight={700} size="lg">
              {Formatter.formatCurrency(period.budget)}
            </Text>
          </Flex>
        </SimpleGrid>
      </Card>
    );
  };

  // const print = () => {
  //   handlePrint();
  // };

  if (!selectedPeriod) return <></>;

  return (
    <GrantMeContainer>
      <Stack spacing={0}>
        <GrantMeTitleContainer>
          <Group spacing="xs">
            <BackButton route={Routes.Archive} />
            <Title order={3}> {selectedPeriod?.title}</Title>
          </Group>
          {/* <Button variant="subtle" onClick={() => print()} leftIcon={<IconPrinter size={14} />}>
            Beslutslista
          </Button> */}
          {selectedPeriod && getFilteredApplications() && (
            <DecisionListPrintButton
              disabled={false}
              decision={selectedPeriod.decisions.find((x) => x.id === decisionId)}
              period={selectedPeriod}
              applications={getFilteredApplications()}
            />
          )}
        </GrantMeTitleContainer>

        {getPeriodDetails(selectedPeriod)}

        {selectedPeriod && selectedPeriod.decisions.length > 1 && (
          <Group align={'center'} pl={28} pr={16} spacing={0} className={classes.bottomBorder}>
            <LiitDropdown
              emptyText={'Filtrera på beslut'}
              menuItems={selectedPeriod.decisions.map((d) => {
                const menuItem: LiitDropdownMenuItem = {
                  id: d.id,
                  text: d.name,
                };
                return menuItem;
              })}
              selectedId={decisionId}
              onChange={setDecisionId}
            />
          </Group>
        )}

        <LiitGrid
          data={getFilteredApplications()}
          columnInfo={
            selectedPeriod.hasRequisition
              ? ArchiveApplicationRequisitionListColumns()
              : ArchiveApplicationListColumns()
          }
          onRowClick={(id: string) => {
            navigate(`/applications/${id}?filterOnType=${FetchForReviewType.Archive}`);
          }}
          highligthOnHoover
          fetchingData={isFetching}
        />
      </Stack>
    </GrantMeContainer>
  );
};
