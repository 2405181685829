import React, { useEffect, useState } from 'react';
import { Badge, Button, Stack, Tabs, Title, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { ApplicationPeriodEditForm } from '../ApplicationPeriodEditForm';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { LiitTabs } from '../../../components/LiitTabs/LiitTabs';
import {
  ApplicationPeriodListColumns1,
  ApplicationPeriodListColumns,
} from './ApplicationPeriodListColumns';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { LiitDrawer } from '../../../components/LiitDrawer/LiitDrawer';
import { ApplicationPeriodStatus } from '../../../models/ApplicationPeriodStatus';
import { ApplicationPeriodResult } from '../../../models/ApplicationPeriodResult';
import { t } from 'ttag';

interface ApplicationPeriodListProps {
  periodStatus: ApplicationPeriodStatus;
}

export const ApplicationPeriodList: React.FC<ApplicationPeriodListProps> = ({ periodStatus }) => {
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const [applicationPeriods, setApplicationPeriods] = useState<
    ApplicationPeriodResult[] | undefined
  >(undefined);

  const [newOpened, setNewOpened] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  // const setColorsbyFormId = (items: ApplicationPeriodResult[]) => {
  //   const colors = setColorsByDistinctId(
  //     theme,
  //     items.map((applicationPeriod) => applicationPeriod.id),
  //   );

  //   items.forEach((applicationPeriod) => {
  //     const { id } = applicationPeriod;
  //     applicationPeriod.categoryColor = colors[id];
  //   });
  // };

  const createApplicationPeriod = () => {
    setNewOpened(true);
  };

  const viewApplicationPeriod = (id: string) => {
    navigate(`/applicationperiods/${id}`);
  };

  const refreshApplicationPeriods = async () => {
    setNewOpened(false);

    if (selectedOrganisation) {
      setIsFetching(true);
      const applicationPeriodsResult = await api.getApplicationPeriods(selectedOrganisation.id);

      setApplicationPeriods(applicationPeriodsResult.filter((x) => x.status === periodStatus));

      setIsFetching(false);
    }
  };

  const deleteApplicationPeriod = async (id: string) => {
    if (selectedOrganisation) {
      await api.deleteApplicationPeriod(selectedOrganisation.id, id);
      await refreshApplicationPeriods();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await refreshApplicationPeriods();
    };

    fetchData();
  }, [selectedOrganisation, periodStatus]);

  const columnInfos =
    periodStatus === ApplicationPeriodStatus.Ongoing ||
    periodStatus === ApplicationPeriodStatus.Upcoming
      ? ApplicationPeriodListColumns
      : ApplicationPeriodListColumns1;

  const statusTexts: Record<ApplicationPeriodStatus, string> = {
    [ApplicationPeriodStatus.Ongoing]: t`Pågående`,
    [ApplicationPeriodStatus.Upcoming]: t`Kommande`,
    [ApplicationPeriodStatus.Completed]: t`Avslutade`,
    [ApplicationPeriodStatus.Archived]: t`Arkiverade`,
  };

  if (!selectedOrganisation) return <></>;

  return (
    <GrantMeContainer size={'xl'}>
      <Stack spacing={0}>
        <GrantMeTitleContainer>
          <Title order={3}>{`${statusTexts[periodStatus]} ${t`perioder`}`}</Title>
          <Button size={'sm'} onClick={() => createApplicationPeriod()} leftIcon={<IconPlus />}>
            Ny Ansökningsperiod
          </Button>
        </GrantMeTitleContainer>
        <LiitGrid
          data={applicationPeriods}
          columnInfo={columnInfos()}
          onRowClick={viewApplicationPeriod}
          fetchingData={isFetching}
        />
      </Stack>
      <LiitDrawer size={'50%'} opened={newOpened} onClose={() => setNewOpened(false)}>
        <ApplicationPeriodEditForm
          applicationPeriod={null}
          selectedOrganisation={selectedOrganisation}
          onClose={() => setNewOpened(false)}
          onSave={refreshApplicationPeriods}
        />
      </LiitDrawer>
    </GrantMeContainer>
  );
};
