import { ActionIcon, Image, useMantineTheme } from '@mantine/core';
import { IconDownload } from '@tabler/icons';
import { motion } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';
import { download } from '../../utils/download';
import { useStyles } from './Styles';

type ImageViewerProps = { getData: () => Promise<string | null>; filename: string };

const BUTTON_SIZE = 'lg';
const HIDE_BUTTONS_TIMER = 1500;

export const ImageViewer: FC<ImageViewerProps> = ({ getData, filename }) => {
  const [objectUrl, setObjectUrl] = useState<string>('');
  const [mouseOverButton, setMouseOverButton] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    getData()
      .then((data) => {
        if (data) {
          setObjectUrl(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);

    return () => clearTimeout(timer.current ?? undefined);
  }, []);

  const mouseMove = () => {
    clearTimeout(timer.current ?? undefined);
    setShowButtons(true);
    if (!mouseOverButton) {
      timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
    }
  };

  const touchMe = () => {
    clearTimeout(timer.current ?? undefined);
    setShowButtons(true);
    timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
  };

  return (
    <div onMouseMove={mouseMove} onTouchStart={touchMe} className={classes.pdfWrapper}>
      <Image src={objectUrl}></Image>;
      <motion.div
        animate={showButtons ? 'show' : 'hidden'}
        variants={{
          show: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        transition={{
          duration: 1,
        }}>
        <div
          className={classes.controllsDownload}
          onMouseEnter={() => setMouseOverButton(true)}
          onMouseLeave={() => setMouseOverButton(false)}>
          <ActionIcon
            color={theme.primaryColor}
            variant={'light'}
            size={BUTTON_SIZE}
            onClick={() => download(objectUrl ?? '', filename)}>
            <IconDownload />
          </ActionIcon>
        </div>
      </motion.div>
    </div>
  );
};
