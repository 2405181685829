import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import './index.css';
import { loginRequest, msalConfig } from './AuthConfig';
import { Settings } from 'luxon';

// TODO: we may need to read this from user settings in the future
Settings.defaultLocale = 'sv';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;

    msalInstance.setActiveAccount(account);
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error?.message.includes('AADB2C90091')) { // The user has canceled
      msalInstance.loginRedirect(loginRequest);
    }
  }

});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </HelmetProvider>,
);
