import { Button, Stack, Title, Text, Group } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconBuildingBank, IconCheck } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { download } from '../../utils/download';
import { handleError } from '../../utils/handleError';
import { PaymentModal } from './PaymentModal';
import { UnpaidListColumns } from './UnpaidListColumns';
import { t } from 'ttag';

export const UnpaidList: React.FC = () => {
  const [payments, setPayments] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { selectedOrganisation } = useSession();
  const navigate = useNavigate();
  const api = useApi();
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const refreshPayments = async () => {
    const result = await api.getUnpaidPayments(selectedOrganisation!.id);
    setPayments(result);
    setCheckedRows([]);
  };

  const downloadPaymentFile = async (id: string) => {
    if (selectedOrganisation) {
      const response = await api.downloadPaymentBatchFile(selectedOrganisation.id, id);

      if (response.ok) {
        const buffer = await response.arrayBuffer();
        const url = window.URL.createObjectURL(new Blob([buffer]));
        await download(url, 'payments.xml');
      } else {
        await handleError(response);
      }
    }
  };

  const markPaymentsAsPaid = async () => {
    return openConfirmModal({
      title: <Title order={3}>{t`Manuell betalning`}</Title>,
      children: (
        <Stack>
          <Text
            size={
              'sm'
            }>{t`Vill du markera ${checkedRows.length} betalning(ar) som manuellt betalda?`}</Text>
        </Stack>
      ),
      labels: { confirm: 'OK', cancel: 'Avbryt' },
      onConfirm: async () => {
        const response = await api.createPaymentBatch(selectedOrganisation!.id, {
          paymentIds: checkedRows,
          bankAccountId: null,
        });

        if (response.ok) {
          await refreshPayments();
        }
      },
    });
  };

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      await refreshPayments();

      setIsFetching(false);
    };

    if (selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation]);

  return (
    <>
      <GrantMeContainer>
        <Stack spacing={0}>
          <GrantMeTitleContainer>
            <Title order={3}>Ej betalade</Title>
            <Group>
              <Button
                size="sm"
                disabled={!(checkedRows.length > 0)}
                variant="subtle"
                onClick={() => markPaymentsAsPaid()}
                leftIcon={<IconCheck size={14} />}>
                Manuell betald
              </Button>
              <Button
                size="sm"
                disabled={!(checkedRows.length > 0)}
                variant="subtle"
                onClick={() => setIsModalOpened(true)}
                leftIcon={<IconBuildingBank size={14} />}>
                Skapa betalfil
              </Button>
            </Group>
          </GrantMeTitleContainer>

          <LiitGrid
            data={payments.filter((x) => x.claimStatus !== 1)}
            columnInfo={UnpaidListColumns()}
            checkedRows={checkedRows}
            onRowChecked={setCheckedRows}
            canCheckRow={(row) => {
              return true;
            }}
            onRowClick={(id: string) => {
              const payment = payments.find((x) => x.id === id);
              navigate(`/applications/${payment.applicationId}`);
            }}
            highligthOnHoover
            fetchingData={isFetching}
          />
        </Stack>
      </GrantMeContainer>

      <PaymentModal
        isOpened={isModalOpened}
        paymentIds={checkedRows}
        onClose={async (paymentFileId) => {
          setIsModalOpened(false);
          if (paymentFileId) {
            await refreshPayments();
            await downloadPaymentFile(paymentFileId);
          }
        }}
      />
    </>
  );
};
