import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  pdfWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  controlls: {
    position: 'absolute',
    zIndex: 500000,
    width: '100%',
    bottom: 54,
    right: 0,
  },
  controllsDownload: {
    position: 'absolute',
    zIndex: 500000,
    top: 24,
    right: 24,
    display: 'flex',
    padding: 8,
    borderRadius: 8,
    backgroundColor: theme.colors.blue[7],
    border: `1px solid ${theme.colors.blue[6]}`,
    justifyContent: 'center',
    alignItems: 'center',
  },
  showButtons: {
    opacity: 1,
  },
  controllPanel: {
    display: 'flex',
    backgroundColor: theme.colors.blue[7],
    border: `1px solid ${theme.colors.blue[6]}`,
    padding: 8,
    borderRadius: 8,
  },

}));