import { useIsAuthenticated } from '@azure/msal-react';
import {
  Stack,
  Title,
  Button,
  Text,
  FileButton,
  Group,
  Table,
  Select,
  Flex,
  useMantineTheme,
  Space,
  Box,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { Csv, isEmpty, isMissing, isPresent, mapPromises } from 'utilitype';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { ApplicationPeriod } from '../../../models/ApplicationPeriod';
import { csvPropertyMap } from './CsvPropertyMap';
import { ImportApplication } from '../../../models/ImportApplication';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { ApplicationStatuses } from '../../application/common/ApplicationStatuses';
import { showFailNotification, showSuccessNotification } from '../../../utils/notificationHelper';
import { openConfirmModal } from '@mantine/modals';
import Validator from '../../../utils/validators';

const requiredFields = [
  'firstname',
  'lastname',
  'identityNumber',
  'clearingNumber',
  'accountNumber',
  'title',
];

interface ImportApplicationsProps {
  applicationPeriod: ApplicationPeriod;
  onComplete: () => void;
}

export const ImportApplications: React.FC<ImportApplicationsProps> = ({
  applicationPeriod,
  onComplete,
}) => {
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const isAuthenticated = useIsAuthenticated();
  const [file, setFile] = useState<File | null>(null);
  const [applications, setApplications] = useState<ImportApplication[]>([]);
  const [delimiter, setDelimiter] = useState<string | null>(null);
  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus | null>(null);
  const theme = useMantineTheme();

  const statusInformation = ApplicationStatuses(theme);
  const statusName = isPresent(applicationStatus)
    ? statusInformation[applicationStatus as ApplicationStatus].title
    : '';

  useEffect(() => {}, [selectedOrganisation, isAuthenticated]);

  useEffect(() => {
    if (isMissing(delimiter) || isMissing(applicationStatus)) {
      return;
    }

    if (file) {
      file
        .text()
        .then((text) => {
          const applicationResult = Csv.fromString<ImportApplication>(
            text,
            csvPropertyMap,
            delimiter,
          );
          setApplications(applicationResult);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [file, delimiter, applicationStatus]);

  const getTemplateImport = () => {
    const a = document.createElement('a');
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([BOM, csvPropertyMap.map((m) => m.header).join(';')], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', 'template.csv');
    a.click();
    URL.revokeObjectURL(url);
  };

  const importApplications = async () => {
    if (selectedOrganisation && isPresent(applications)) {
      const { id } = selectedOrganisation;
      try {
        await api.importApplications(id, {
          applicationPeriodId: applicationPeriod.id,
          applications,
          applicationStatus: applicationStatus as ApplicationStatus,
        });

        showSuccessNotification(
          t`Lyckad import`,
          t`${applications.length} ansökningar har importerats i systemet med statusen '${statusName}'`,
        );
        onComplete();
      } catch {
        showFailNotification(
          t`Fel vid import`,
          t`Något gick fel vid filimport, se över så att filen är i rätt format och försök igen.`,
        );
      }
    }
  };

  const openModal = () => {
    return openConfirmModal({
      title: <Title order={3}>{t`Import av ansökningar`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>
            {t`Du är på väg att importera ${applications.length} ansökningar i perioden `}
            <Text color={theme.primaryColor} span>
              {applicationPeriod.title}
            </Text>
            {t` som kommer få status ${statusName}.`}
          </Text>
          <Text
            size={
              'sm'
            }>{t`Detta går inte att ångra. Är du helt säker på att du vill göra denna import?`}</Text>
        </Stack>
      ),
      labels: { confirm: 'Importera', cancel: 'Avbryt' },
      onCancel: () => {},
      onConfirm: () => importApplications(),
    });
  };

  const validateFields = csvPropertyMap.map((c) => {
    const missingValue = applications.some((a) => !isPresent(a[c.key]));
    return { key: c.key, field: c.header, hasMissing: missingValue };
  });

  const errors = validateFields.filter(
    (vf) => vf.hasMissing && requiredFields.some((rf) => rf === vf.key),
  );

  const validateAndGetCellValue = (application, mapInfo) => {
    let valueValid = true;
    const value = application[mapInfo.key];

    if (mapInfo.key === 'email') {
      valueValid = Validator.validateEmail(value);
    }

    if (mapInfo.key === 'identityNumber') {
      const validFormat = Validator.validateIdentityNumberPerson(value);
      const validControlNumber = Validator.validateIdentityNumberSwedish(value);

      valueValid = validFormat && validControlNumber;
    }

    if (!valueValid) {
      //setFaultyCells(true);
    }

    return (
      <td style={{ color: valueValid ? 'unset' : 'red' }} key={`${mapInfo.key}`}>
        {application[mapInfo.key]}
      </td>
    );
  };

  const hasInvalidCells = () => {
    return applications.some((a) => {
      return csvPropertyMap.some((map) => {
        const value = a[map.key];
        if (map.key === 'email') {
          if (!Validator.validateEmail(a[map.key])) {
            return true;
          }
        }

        if (map.key === 'identityNumber') {
          const validFormat = Validator.validateIdentityNumberPerson(value as string);
          const validControlNumber = Validator.validateIdentityNumberSwedish(value as string);

          if (!validFormat || !validControlNumber) {
            return true;
          }
        }
      });
    });
  };

  let invalidCells = false;
  if (applications && applications.length > 0 && delimiter && applicationStatus) {
    invalidCells = hasInvalidCells();
  }

  return (
    <Box w={'100%'}>
      <Stack>
        <Title order={3}>{t`Importera från Excel (.csv)`}</Title>
        <Text w={500} color={'dimmed'} size={'sm'}>
          En mall finns att ladda ner{' '}
          <Text
            color={'blue'}
            style={{ cursor: 'pointer' }}
            component={'a'}
            onClick={() => getTemplateImport()}>
            här
          </Text>
          . När du fyllt i filen med informationen om ansökarna exporterar du till .csv
        </Text>
        <Text w={500} color={'dimmed'} size={'sm'}>
          Efter det väljder du vilken avgränsare filen använder sig av och laddar upp. Du får nu se
          en lista av vad som kommer importeras och kan nu också välja vilken status du vill att
          ansökningarna ska få.
        </Text>
        <Group position={'apart'} align={'end'}>
          <Flex align={'end'} gap={'lg'}>
            <Select
              label={t`Avgränsare`}
              placeholder={t`Välj avgränsare`}
              value={delimiter}
              onChange={setDelimiter}
              data={[
                { value: ',', label: 'Kommatecken' },
                { value: ';', label: 'Semikolon' },
              ]}
            />
            <Select
              label={t`Slutstatus`}
              placeholder={t`Välj status på ansökan`}
              value={applicationStatus?.toString()}
              onChange={(value) => {
                setApplicationStatus(Number(value));
              }}
              data={[
                {
                  value: ApplicationStatus.Approved.toString(),
                  label: statusInformation[ApplicationStatus.Approved].title,
                },
                {
                  value: ApplicationStatus.Declined.toString(),
                  label: statusInformation[ApplicationStatus.Declined].title,
                },
                {
                  value: ApplicationStatus.Submitted.toString(),
                  label: statusInformation[ApplicationStatus.Submitted].title,
                },
              ]}
            />
            <Flex justify={'center'} align={'center'} gap={'xs'}>
              <FileButton onChange={setFile} accept=".csv">
                {(props) => <Button {...props}>{t`Ladda upp fil`}</Button>}
              </FileButton>
              {file && (
                <Text size="sm" weight={'bolder'} align="center">
                  {`${t`Fil:`} ${file.name}`}
                </Text>
              )}
            </Flex>
          </Flex>
          <Button
            fullWidth={false}
            onClick={openModal}
            disabled={
              invalidCells ||
              isEmpty(applications) ||
              isMissing(delimiter) ||
              isMissing(applicationStatus) ||
              errors.length > 0
            }>
            Importera
          </Button>
        </Group>
        {errors.length === 0 && applications.length > 0 && (
          <Table fontSize={'xs'} pb={120}>
            <thead>
              <tr>
                {csvPropertyMap.map((map) => (
                  <th key={`${map.key}`}>{map.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {applications.map((application, index) => (
                <tr key={`${application.identityNumber}_${index}`}>
                  {csvPropertyMap.map((map) => validateAndGetCellValue(application, map))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {invalidCells && (
          <Text
            size={'xs'}
            color={theme.colors.red[5]}>{t`Det finns celler som inte validerar`}</Text>
        )}
        {errors.length > 0 && (
          <Stack spacing={0}>
            <Text
              size={'xs'}
              color={theme.colors.red[5]}
              weight={'bold'}>{t`Det verkar vara fel i filen. Har du valt rätt avskiljare?`}</Text>
            <Space h={'sm'} />
            <Text
              size={'xs'}
              color={theme.colors.red[5]}
              weight={
                'bold'
              }>{t`Följande obligatoriska fält saknas på en eller flera rader.`}</Text>
            {errors.map((errorField) => {
              return (
                <Text
                  size={'xs'}
                  color={theme.colors.red[5]}
                  key={`${errorField.key}_KEY`}>{`${errorField.field}`}</Text>
              );
            })}
          </Stack>
        )}
        <Space h={'xl'} />
      </Stack>
    </Box>
  );
};
