import { Text, Flex, Group } from '@mantine/core';
import { IconAlertTriangle, IconCheck, IconTriangle, IconX } from '@tabler/icons';
import { t } from 'ttag';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { TextWithPopover } from './TextWithPopover';
import { HoverTooltip } from '../../../components/HoverTooltip';



export const RenderReviewersCell: RenderCell = (
  rowInformation?: CellRenderInformation,
) => {
  const { rowValues, theme } = rowInformation as CellRenderInformation;
  const { reviewCompletionStatus, maxReviewersCount } = rowValues;

  const count = reviewCompletionStatus.reduce((a, b) => {
    if (b.reviewDone) {
      return a + 1;
    }
    return a;
  }, 0);

  const getPopoverContent = () => {
    return <Flex p={12} direction={'column'}>
    {reviewCompletionStatus.map((info) => (
      <Group spacing={12} key={info.name} position={'apart'}>
        <Text size={'xs'} weight={500}>
          {info.name}
        </Text>
        {info.isBiased ? (
          <Text size={'xs'} color={theme.colors.red[7]} weight={600}>
            {t`Jäv`}
          </Text>
        ) : info.reviewDone ? (
          <IconCheck size={14} color={theme.colors.green[5]} />
        ) : (
          <IconX size={14} color={theme.colors.red[3]} />
        )}
      </Group>
    ))}
  </Flex>;
  };

  return (
      <HoverTooltip content={getPopoverContent()}>
        <Group position="center" spacing={'sm'}>
          {`${count}/${maxReviewersCount}`}
          {reviewCompletionStatus.some(x => x.isBiased) && 
            <IconAlertTriangle color={theme.colors.orange[5]}></IconAlertTriangle>
          }         
        </Group>
      </HoverTooltip>
  );
};
