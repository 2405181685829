import React from 'react';
import { Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

interface HoverTooltipProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
}

export const HoverTooltip: React.FC<HoverTooltipProps> = ({ content, children }) => {
  const [opened, { close, open }] = useDisclosure(false);

  const getContent = () => {

    if ((typeof content === 'string' || content instanceof String)) {
      return <Text size="sm" weight={500}>{content}</Text>;
    } else {
      return content;
    }
  };

  return (
    <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <div onMouseEnter={open} onMouseLeave={close}>
          {children}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        { getContent() }
      </Popover.Dropdown>
    </Popover>
  );
};
