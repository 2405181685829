import { ToolboxItems } from '../FormBuilderToolbox/ToolBoxItems';
import { FormElement } from '../models/FormElement';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import { FormElementType } from '../models/FormElementType';
import { t } from 'ttag';
import { LanguageCode } from '../../../models/LanguageCode';
import { FormElementTextArea } from '../models/FormElementTextArea';
import { FormElementTypedTextBox } from '../models/FormElementTypedTextBox';
import { FormElementRepeater } from '../models/FormElementRepeater';
import { TextBoxInputType } from './TypedTextBox/TextBoxInputType';
import { FormElementWithControlQuestions } from '../models/FormElementWithControlQuestion';

export const getGenericDefaults = (elementType: FormElementType, defaultValue: unknown = null) =>
  ({
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    value: defaultValue,
    size: 12,
    labelAndTextPosition: 'left',
  } as FormElement);

export const getTextAreaDefaults = (elementType: FormElementType) =>
  ({
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    value: '',
    minRows: 3,
    maxRows: 3,
    size: 12,
    helpText: null,
    maxCharCount: null,
    labelAndTextPosition: 'left',
  } as FormElementTextArea);

export const getTypedTextBoxDefaults = (elementType: FormElementType) =>
  ({
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    value: '',
    size: 12,
    helpText: null,
    inputType: TextBoxInputType.Plain,
    labelAndTextPosition: 'left',
  } as FormElementTypedTextBox);

export const getAmountTableDefaults = (elementType: FormElementType) => {
  const options = [
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 1` }] },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 2` }] },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 3` }] },
  ];

  const values = {};
  values[options[0].id] = null;
  values[options[1].id] = null;
  values[options[2].id] = null;

  return {
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    options: options,
    value: values,
    size: 12,
    helpText: null,
    labelAndTextPosition: 'left',
  } as FormElement;
};

export const getElementWithOptionsDefaults = (
  elementType: FormElementType,
  defaultValue: string | null | [] | Record<string, number> = NIL_UUID,
) => ({
  id: uuidv4(),
  label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
  type: elementType,
  required: false,
  description: [{ language: LanguageCode.sv, text: '' }],
  options: [
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 1` }] },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 2` }] },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 3` }] },
  ],
  value: defaultValue,
  size: 12,
  helpText: null,
  labelAndTextPosition: 'left',
} as FormElement);

export const getElementWithOptionsAndControlQuestionDefaults = (
  elementType: FormElementType,
  defaultValue: string | null | [] | Record<string, number> = NIL_UUID,
) => ({
  id: uuidv4(),
  label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
  type: elementType,
  required: false,
  description: [{ language: LanguageCode.sv, text: '' }],
  options: [
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 1` }], isMandatory: false },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 2` }], isMandatory: false },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 3` }], isMandatory: false },
  ],
  value: defaultValue,
  size: 12,
  helpText: null,
  controlQuestionErrorText: null,
  labelAndTextPosition: 'left',
} as FormElementWithControlQuestions);

export const getRepeaterDefaults = (elementType: FormElementType, defaultValue: unknown = null) =>
  ({
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    value: [],
    options: [],
    template: [],
    size: 12,
    addButtonText: [{ language: LanguageCode.sv, text: 'Lägg till' }],
    labelsOnFirstGroup: false,
    sumNumberFields: false,
    helpText: null,
    labelAndTextPosition: 'left',
  } as FormElementRepeater);
