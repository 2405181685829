import { createStyles, Flex, keyframes, Navbar } from '@mantine/core';
import { useMediaQuery, useSessionStorage } from '@mantine/hooks';
import {
  IconArchive,
  IconCalendar,
  IconFileText,
  IconGavel,
  IconMoneybag,
  IconReport,
  IconSearch,
  IconSettings,
  TablerIcon,
} from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'ttag';
import { useSession } from '../hooks/useSession';
import { LinksGroup } from './NavbarLinksGroup';
import { navbarStyles } from './NavbarStyles';
import { Navigation } from './Navigation';
import { Routes } from './Routes';
import { ProductVariant } from '../models/ProductVariant';
import { Role } from '../models/Role';

interface MenuItemData {
  label: string;
  link?: string;
  roles: Role[];
  icon?: TablerIcon;
  items?: MenuItemData[];
  visible: boolean;
}

const NAV_WIDTH = 240;

export const animate = keyframes({
  '0%': {
    opacity: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  '99%': {
    opacity: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  '100%': {
    opacity: 0,
    maxHeight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    overflow: 'hidden',
  },
});

const animateTextStyles = createStyles((theme) => ({
  textAnimation: {
    textAlign: 'center',
    padding: theme.spacing.xl,
    animation: `${animate}`,
    animationDirection: 'alternate',
    animationDuration: '0.2s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
}));

export const GrantMeNavbar: React.FC = () => {
  const { classes, theme, cx } = navbarStyles();
  const { classes: animateClasses } = animateTextStyles();
  const smallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
  const navigate = useNavigate();
  const { selectedOrganisation } = useSession();
  const [expandedItem, setExpandedItem] = useState<string | undefined>(undefined);
  const [openNavbar, setOpenNavBar] = useSessionStorage({
    key: 'open-navbar',
    defaultValue: false,
  });

  const menuItems = [
    {
      label: t`Inkorg`,
      icon: IconSearch,
      roles: [Role.Reviewer, Role.Approver, Role.Administrator],
      visible: selectedOrganisation?.productVariant === ProductVariant.Large,
      links: [
        {
          label: 'Att granska',
          link: Navigation[Routes.Reviews].link + '/todo',
          counter: 'submittedApplications',
          visible: selectedOrganisation?.productVariant === ProductVariant.Large,
        },
        {
          label: 'Granskade',
          link: Navigation[Routes.Reviews].link + '/completed',
          counter: 'reviewedApplications',
          visible: selectedOrganisation?.productVariant === ProductVariant.Large,
        },
      ],
    },
    {
      label: t`Ansökningar`,
      icon: IconFileText,
      roles: [Role.Approver, Role.Administrator],
      visible: true,
      link: Navigation[Routes.Applications].link,
      // links: [
      //   {
      //     label: 'Inlämnade',
      //     link: Navigation[Routes.Applications].link + '/submitted',
      //     visible: true,
      //     //counter: 'submittedApplications',
      //   },
      //   {
      //     label: 'Beviljade',
      //     link: Navigation[Routes.Applications].link + '/approved',
      //     visible: true,
      //     //counter: 'approvedApplications',
      //   },
      //   {
      //     label: 'Nekade',
      //     link: Navigation[Routes.Applications].link + '/declined',
      //     visible: true,
      //     // counter: 'declinedApplications',
      //   },
      //   {
      //     label: 'Borttagna',
      //     link: Navigation[Routes.Applications].link + '/deleted',
      //     visible: true,
      //     // counter: 'deletedApplications',
      //   },
      // ],
    },
    {
      label: 'Beslut',
      icon: IconGavel,
      roles: [Role.Approver, Role.Administrator],
      link: Navigation[Routes.Approvals].link,
      visible: true,
    },
    {
      label: 'Betalningar',
      icon: IconMoneybag,
      roles: [Role.Administrator],
      visible: true,
      links: [
        { label: 'Ej betalade', link: Navigation[Routes.Payments].link + '/unpaid', visible: true },
        {
          label: 'Ej rekvirerade',
          link: Navigation[Routes.Payments].link + '/unclaimed',
          visible: true,
        },
        { label: 'Betalade', link: '/paymentbatches', visible: true },
      ],
    },
    {
      label: 'Återrapporter',
      icon: IconReport,
      roles: [Role.Approver, Role.Administrator],
      link: Navigation[Routes.ApplicationReports].link,
      visible: true,
    },
    {
      label: 'Inställningar',
      icon: IconSettings,
      roles: [Role.Administrator],
      visible: true,
      links: [
        { label: 'Allmänt', link: Navigation[Routes.Settings].link + '/general', visible: true },
        { label: 'Användare', link: Navigation[Routes.Settings].link + '/users', visible: true },
        {
          label: 'Bankkonton',
          link: Navigation[Routes.Settings].link + '/bankaccounts',
          visible: true,
        },
        {
          label: 'Bedömningskriterier',
          link: Navigation[Routes.Settings].link + '/criterias',
          visible: true,
        },
      ],
    },
    {
      label: 'Perioder',
      icon: IconCalendar,
      roles: [Role.Administrator],
      visible: true,
      links: [
        {
          label: 'Pågående',
          link: Navigation[Routes.ApplicationPeriods].link + '/ongoing',
          visible: true,
        },
        {
          label: 'Kommande',
          link: Navigation[Routes.ApplicationPeriods].link + '/upcomming',
          visible: true,
        },
        {
          label: 'Avslutade',
          link: Navigation[Routes.ApplicationPeriods].link + '/completed',
          visible: true,
        },
        {
          label: 'Arkiverade',
          link: Navigation[Routes.ApplicationPeriods].link + '/archived',
          visible: true,
        },
      ],
    },
    {
      label: 'Arkiv',
      icon: IconArchive,
      roles: [Role.Approver, Role.Administrator],
      link: Navigation[Routes.Archive].link,
      visible: true,
    },
  ] as MenuItemData[];

  useEffect(() => {
    setOpenNavBar(!smallScreen);
  }, [smallScreen]);

  const links = menuItems
    .map((item) => {
      if (item.roles.some((x) => x === selectedOrganisation?.role)) {
        return (
          <LinksGroup
            {...item}
            key={item.label}
            linkPressed={(route: string) => {
              if (route) {
                navigate(route);
              }
            }}
            expandedItem={expandedItem}
            setExpandedItem={setExpandedItem}
          />
        );
      }
    })
    .filter(Boolean);

  return (
    <Navbar
      className={classes.ourNavBar}
      w={openNavbar ? NAV_WIDTH : 0}
      style={{ position: smallScreen ? 'fixed' : 'unset' }}>
      <Navbar.Section className={cx()} grow p={16} style={{ overflowX: 'hidden' }}>
        <Flex
          direction={'column'}
          className={cx(classes.linksInner, { [animateClasses.textAnimation]: !openNavbar })}>
          {links}
        </Flex>
      </Navbar.Section>
    </Navbar>
  );
};
