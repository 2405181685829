import { ActionIcon, Group, Tooltip, useMantineTheme } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconList, IconPrinter } from '@tabler/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'ttag';
import { isMissing } from 'utilitype';
import { ApplicationStatusBadge } from '../../features/common/application/ApplicationStatusBadge';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { Application } from '../../models/Application';
import { FetchForReviewType } from '../../models/PagedResult';

interface ViewNavigationProps {
  filterPeriod: boolean | undefined;
  application: Application;
  context: FetchForReviewType | undefined;
}

export const ViewNavigation: React.FC<ViewNavigationProps> = ({
  application,
  context,
  filterPeriod,
}) => {
  const theme = useMantineTheme();
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const [tooltipPrevious, setTooltipPrevious] = useState(false);
  const [tooltipNext, setTooltipNext] = useState(false);
  const [tooltipList, setTooltipList] = useState(false);
  const [tooltipPrint, setTooltipPrint] = useState(false);
  const navigate = useNavigate();

  const downloadPdf = async () => {
    if (selectedOrganisation && application && application.id) {
      const result = await api.downloadApplicationPdf(selectedOrganisation.id, application.id);

      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', application?.number + '.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  };

  const getQueryFilters = (): string => {
    const queryList = Array<string>();

    if (filterPeriod != undefined) {
      queryList.push(`filterOnPeriod=${filterPeriod}`);
    }

    if (context != undefined) {
      queryList.push(`filterOnType=${context}`);
    }

    return queryList.length > 0 ? `?${queryList.join('&')}` : '';
  };

  const getTooltipTextPrevious = () => {
    // const actionText = !context
    //   ? ''
    //   : context === FetchForReviewType.ReviewTodo
    //   ? ' att hantera'
    //   : t` granskade`;
    // const periodText = !context ? '' : t` i ${application.periodTitle}`;

    //${actionText}${periodText}`,
    return t`Föregående ansökan`;
  };

  const getTooltipTextNext = () => {
    return t`Nästa ansökan`;
  };

  const getRoute = () : string => {
    switch (context) {
      case FetchForReviewType.ReviewTodo:
        return '/reviews/todo';
      case FetchForReviewType.ReviewCompleted:
        return '/reviews/completed';
      case FetchForReviewType.ReviewDeleted:
        return '/applications/deleted';
      case FetchForReviewType.Reports:
        return  '/applicationreports';
      case FetchForReviewType.Archive:
        return `/archive/applicationperiods/${application.periodId}`;
      case FetchForReviewType.Approval:
        return `/approvals/${application.periodId}`;
      default:
        return '/applications';
    }    
  };

  const backToList = () => {
    navigate(getRoute());
  };

  return (
    <Group position={'right'} spacing={'md'} noWrap>
      <ApplicationStatusBadge status={application.status} />
      {
        <Tooltip
          label={'Visa PDF'}
          withArrow
          onMouseEnter={() => setTooltipPrint(true)}
          onMouseLeave={() => setTooltipPrint(false)}
          opened={isMissing(print) ? false : tooltipPrint}
          color={theme.primaryColor}>
          <ActionIcon
            data-testid={'print-button'}
            size={'md'}
            variant="light"
            color={'blue'}
            onClick={async () => {
              await downloadPdf();
            }}>
            <IconPrinter />
          </ActionIcon>
        </Tooltip>
      }

      <Tooltip
        label={t`Tillbaka till listan`}
        withArrow
        onMouseEnter={() => setTooltipList(true)}
        onMouseLeave={() => setTooltipList(false)}
        opened={tooltipList}
        color={theme.primaryColor}>
        <ActionIcon
          data-testid={'next-button'}
          size={'md'}
          variant="light"
          color={'blue'}
          onClick={() => {
            backToList();
          }}>
          <IconList />
        </ActionIcon>
      </Tooltip>

      <Tooltip
        label={getTooltipTextPrevious()}
        withArrow
        onMouseEnter={() => setTooltipPrevious(true)}
        onMouseLeave={() => setTooltipPrevious(false)}
        opened={isMissing(application.previousApplicationId) ? false : tooltipPrevious}
        color={theme.primaryColor}>
        <ActionIcon
          data-testid={'previous-button'}
          size={'md'}
          variant={'light'}
          color={'blue'}
          disabled={isMissing(application.previousApplicationId)}
          onClick={() => {
            navigate(`${getRoute()}/${application.previousApplicationId}${getQueryFilters()}`);
          }}>
          <IconArrowLeft />
        </ActionIcon>
      </Tooltip>

      <Tooltip
        label={getTooltipTextNext()}
        withArrow
        onMouseEnter={() => setTooltipNext(true)}
        onMouseLeave={() => setTooltipNext(false)}
        opened={isMissing(application.nextApplicationId) ? false : tooltipNext}
        color={theme.primaryColor}>
        <ActionIcon
          data-testid={'next-button'}
          size={'md'}
          variant={'light'}
          color={'blue'}
          disabled={isMissing(application.nextApplicationId)}
          onClick={() => {
            navigate(`${getRoute()}/${application.nextApplicationId}${getQueryFilters()}`);
          }}>
          <IconArrowRight />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};
