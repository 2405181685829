import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Title, Text, Group, Select, Divider, TextInput, createStyles, Flex } from '@mantine/core';
import { useSession } from '../../../hooks/useSession';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { IconChevronDown, IconMoodEmpty, IconSearch } from '@tabler/icons';
import { ApplicationsListColumns } from './ApplicationsListColumns';
import { LiitDropdown, LiitDropdownMenuItem } from '../../../components/LiitDropdown/LiitDropdown';
import { t } from 'ttag';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { useDebouncedState, useSessionStorage } from '@mantine/hooks';
import { ApplicationItem } from '../../../models/ApplicationItem';
import { PagedResult } from '../../../models/PagedResult';
import { setColorsByDistinctId } from '../../../components/LiitGrid/SetColorsByDistinctId';

export const BORDER_COLOR = '#cbd0dd'; //gray[3]

export const useStyles = createStyles(() => ({
  filterGroup: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
}));

export const ApplicationsList: React.FC = ({}) => {
  const [applications, setApplications] = useState<PagedResult<ApplicationItem> | undefined>(undefined);
  const [applicationPeriods, setApplicationPeriods] = useState<LiitDropdownMenuItem[] | null>(null);

  const navigate = useNavigate();
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const [isFetching, setIsFetching] = useState(true);

  const [selectedApplicationStatus, setSelectedApplicationStatus] = useState<string | null>(null);
  const [keyword, setKeyword] = useDebouncedState<string | null>(null, 300);
  const { classes, theme } = useStyles();

  const [selectedApplicationPeriodId, setSelectedApplicationPeriodId] = useSessionStorage<string | null | undefined>({
    key: 'applications-filter-period',
    defaultValue: undefined,
  });

  const setColorsbyFormId = (items: ApplicationItem[], periods: LiitDropdownMenuItem[]) => {
    items.forEach((application) => {
      const { formId } = application;
      const colorItem = periods.find((p) => p.id === formId);
      application.categoryColor = {
        color: colorItem?.color ?? 'white',
        inverted: colorItem?.inverted ?? 'black',
      };
    });
  };

  useEffect(() => {
    const init = async () => {
      setIsFetching(true);
      const response = await api.getApplications(
        selectedOrganisation!.id,
        selectedApplicationStatus ? parseInt(selectedApplicationStatus) : undefined,
        keyword,
        selectedApplicationPeriodId,
      );
      
      const ap = response.applicationPeriods;
      const colors = setColorsByDistinctId(theme, ap.map(x => x.id));

      const preiodsInList = response.applicationPeriods.map((x) => (
        { 
          id: x.id, 
          text: x.title,
          color: colors[x.id].color,
          inverted: colors[x.id].inverted,
        }));

      setApplicationPeriods(preiodsInList);

      const applicationList = response.applications;
      
      setColorsbyFormId(applicationList, preiodsInList);

      setApplications(applicationList);
    
      setIsFetching(false);
    };

    init();
  }, [selectedOrganisation, selectedApplicationPeriodId, selectedApplicationStatus, keyword]);

  return (
    <GrantMeContainer>
      <Stack spacing={0} style={{ flex: 1, overflow: 'hidden' }}>
        <GrantMeTitleContainer>
          <Title order={3}>{t`Ansökningar`}</Title>
        </GrantMeTitleContainer>
        <Group pl={'xl'} className={classes.filterGroup} spacing={8} h={36}>
          <Text size={'sm'} weight={600}>{t`Period:`}</Text>
          <LiitDropdown
            emptyText={t`Alla`}
            menuItems={applicationPeriods}
            onChange={(periodId) => {
              setSelectedApplicationPeriodId(periodId);
            }}
            selectedId={selectedApplicationPeriodId}
            showColorBox={true}
          />
          <Divider orientation="vertical"/>
          <Text size={'sm'} weight={600}>{t`Status:`}</Text>
          <LiitDropdown
            emptyText={t`Alla`}
            menuItems={[
              { id: '1', text: t`Inlämnade` },
              { id: '2', text: t`Nekade` },
              { id: '3', text: t`Beviljade` },
              { id: '4', text: t`Borttagna` },
            ]}
            onChange={(id) => {
              setSelectedApplicationStatus(id);
            }}
            selectedId={selectedApplicationStatus}
            showColorBox={false}
          />
          <Divider orientation="vertical" />
          <TextInput
            variant="unstyled"
            placeholder="Sök ..."
            onChange={(event) => {
              setKeyword(encodeURIComponent(event.currentTarget.value));
            }}
          />
        </Group>
        <Flex style={{ flex: 1, overflow: 'auto' }}>
          <LiitGrid
            data={applications}
            columnInfo={ApplicationsListColumns()}
            emptyText={'nothing'}
            emptyIcon={IconMoodEmpty}
            onRowClick={(id: string) => {
              navigate(`/applications/${id}`);
            }}
            fetchingData={isFetching}
            isPrintable={true}          
          />
        </Flex>
      </Stack>
    </GrantMeContainer>
  );
};
