export interface PaginationModel {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
}

export type PagedResult<T> = {
  data: T[];
  pagination: PaginationModel;
};

export enum FetchForReviewType {
  ReviewTodo = 0,
  ReviewCompleted = 1,
  ReviewDeleted = 2,
  ReviewAll = 3,
  Approval = 4,
  Payments = 5,
  Reports = 6,
  Archive = 7,
  Search = 8,
}
