/* eslint-disable @typescript-eslint/no-unused-vars */
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import React from 'react';
import { t } from 'ttag';
import { CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { Flex, Group, Stack, Text } from '@mantine/core';
import Formatter from '../../utils/formatter';
import { RenderDecisionButtonsCell } from '../application-approval/application-approval-list/RenderDecisionButtonsCell';
import { RenderApplicationStatusCell } from '../common/grid-columns/RenderApplicationStatusCell';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const SearchResultListViewColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Diarienr',
    propertyName: 'number',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Inlämnad',
    propertyName: 'submittedDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Sökande',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'E-post',
    propertyName: 'email',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Status',
    propertyName: 'status',
    renderCell: RenderApplicationStatusCell,
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
];
