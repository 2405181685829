import {
  Button,
  Checkbox,
  Stack,
  Textarea,
  TextInput,
  Group,
  ActionIcon,
  SimpleGrid,
  NumberInput,
  Select,
  Flex,
  Box,
  Input,
  Text,
  SelectItem,
} from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons';
import { FC, forwardRef, useEffect } from 'react';
import { useForm } from '@mantine/form';
import { v4 as uuidv4 } from 'uuid';
import { FormElement } from './models/FormElement';
import { FormElementType } from './models/FormElementType';
import { t } from 'ttag';
import { LanguageCode } from '../../models/LanguageCode';
import LanguageHandler from '../../utils/languageHandler';
import { InputTypesSetting } from './FormBuilderToolbox/ToolboxItemSettings';
import { ToolboxItems } from './FormBuilderToolbox/ToolBoxItems';
import { TranslationItem } from '../../models/TranslationItem';
import { INPUT_MAX_LENGTH } from '../../App';

interface FormBuilderPropertyViewProps {
  selectedElement: FormElement & any;
  apply: (element: FormElement) => void;
  close: () => void;
  availableInputTypes?: InputTypesSetting[];
}

export const FormBuilderPropertyView: FC<FormBuilderPropertyViewProps> = ({
  selectedElement,
  apply,
  close,
  availableInputTypes,
}) => {
  const form = useForm({
    initialValues: {
      ...selectedElement,
      sizeSelectValue: selectedElement.size?.toString() ?? null,
      inputTypeSelectValue: selectedElement.inputType?.toString() ?? null,
    },
    validate: {
      label: (value) =>
        LanguageHandler.getTextByLanguage(value, LanguageCode.sv).trim() ? null : t`Ange ett värde`,
      addButtonText: (value) =>
        selectedElement.type !== FormElementType.Repeater ||
        LanguageHandler.getTextByLanguage(value, LanguageCode.sv).trim()
          ? null
          : t`Knappen måste ha ett värde`,

      options: {
        label: (value) =>
          LanguageHandler.getTextByLanguage(value, LanguageCode.sv).trim()
            ? null
            : t`Ange ett värde`,
      },
    },
  });

  const renderOptions = (element: FormElement) => {
    const canHaveMandatoryOptions =
      element.type === FormElementType.Dropdown ||
      element.type === FormElementType.RadioButtonGroup;

    return (
      <>
        <Input.Wrapper
          label={t`Värden`}
          description={
            canHaveMandatoryOptions
              ? t`Checkade värden fungerar som kontrollfrågor. Om inga är valda görs ingen kontroll`
              : undefined
          }>
          <Stack pr={8} pt={canHaveMandatoryOptions ? 8 : 0}>
            {element.options.map((option, index) => (
              <Group key={`${element.id}_option_${option.id}`} position={'apart'} align={'center'}>
                {canHaveMandatoryOptions && (
                  <Checkbox
                    pl={4}
                    checked={option.isMandatory ?? false}
                    onChange={(event) => {
                      const isChecked = event.currentTarget.checked;

                      if (
                        !isChecked &&
                        !element.options.filter((x) => x !== option).some((x) => x.isMandatory)
                      ) {
                        form.setFieldValue('controlQuestionErrorText', null);
                      }

                      form.setFieldValue(
                        `options.${index}.isMandatory`,
                        event.currentTarget.checked,
                      );
                    }}
                  />
                )}
                <TextInput
                  {...form.getInputProps(`options.${index}.label.0.text`)}
                  style={{ flexGrow: 1 }}
                  maxLength={INPUT_MAX_LENGTH}
                />
                <ActionIcon
                  variant="subtle"
                  onClick={() =>
                    form.insertListItem(
                      'options',
                      {
                        id: uuidv4(),
                        label: [{ language: LanguageCode.sv, text: '* FYLL I VÄRDE *' }],
                      },
                      index + 1,
                    )
                  }>
                  <IconPlus size={16} />
                </ActionIcon>
                <ActionIcon variant="subtle" onClick={() => form.removeListItem('options', index)}>
                  <IconMinus size={16} />
                </ActionIcon>
              </Group>
            ))}
          </Stack>
        </Input.Wrapper>
        {canHaveMandatoryOptions && element.options.some((x) => x.isMandatory) && (
          <TextInput
            label={t`Kontrollfråga feltext`}
            description={t`Denna text visas om användaren inte väljer ett svar som är checkat`}
            value={
              form.values.controlQuestionErrorText
                ? LanguageHandler.getTextByLanguage(
                  form.values.controlQuestionErrorText,
                  LanguageCode.sv,
                )
                : ''
            }
            maxLength={INPUT_MAX_LENGTH}
            onChange={(event) => {
              const eventValue = event.currentTarget.value;
              if (eventValue) {
                const newValue: TranslationItem = { language: LanguageCode.sv, text: eventValue };
                form.setFieldValue('controlQuestionErrorText', [newValue]);
              } else {
                form.setFieldValue('controlQuestionErrorText', null);
              }
            }}
          />
        )} 
      </>
    );
  };

  type ItemProps = {
    label: string,
    limit: string,
    typeCount?: number,
  } & SelectItem;

  // eslint-disable-next-line react/display-name
  const SelectItem2 = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, limit, typeCount, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap position={'apart'}>
          <Text size="xs" weight={500}>
            {label}
          </Text>
          {typeCount !== undefined && (
            <Text size="xs" opacity={0.65} pr={8}>
              {`${typeCount ?? 0} / ${limit ?? '*'}`}
            </Text>
          )}
        </Group>
      </div>
    ),
  );

  const renderInputType = () => (
    <Select
      styles={(theme) => ({
        separatorLabel: {
          fontWeight: 600,
          color: theme.colors[theme.primaryColor][7],
          '&:after': {
            borderTopColor: theme.colors[theme.primaryColor][6],
          },
        },
      })}
      label={t`Typ`}
      itemComponent={SelectItem2}
      data={availableInputTypes?.map((inputType) => {
        const groupItems = availableInputTypes.some((x) => x.mandatory);
        return {
          value: inputType.type.toString(),
          limit: inputType.limit ?? '*',
          label: inputType.label,
          disabled: inputType.limitReached,
          typeCount: inputType.count,
          group: !groupItems ? null : inputType.mandatory ? t`Obligatorisk` : t`Övriga`,
        } as ItemProps;
      }) ?? [] as ItemProps[]}
      {...form.getInputProps('inputTypeSelectValue')}
    />
  );

  useEffect(() => {
    if (!form.values.minRows || !form.values.maxRows) {
      return;
    }

    if (form.values.minRows > form.values.maxRows) {
      form.setFieldValue('maxRows', form.values.minRows);
    }
  }, [form.values.minRows]);

  const submit = () => {
    const result = form.validate();

    if (!result.hasErrors) {
      const parsedResult = { ...form.values, size: Number(form.values.sizeSelectValue), inputType: Number(form.values.inputTypeSelectValue) };
      apply(parsedResult);
    }
  };

  if (selectedElement.type === FormElementType.SectionTitle) {
    return (
      <Flex direction={'column'} w={'100%'}>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <form>
            <Stack spacing={'lg'} p={0}>
              <TextInput label={t`Titel`} {...form.getInputProps('label.0.text')}  maxLength={INPUT_MAX_LENGTH}/>
            </Stack>
          </form>
        </Box>
        <Group position={'right'} pt={'xl'}>
          <Button onClick={() => submit()}>{t`Spara`}</Button>
          <Button onClick={() => close()}>{t`Avbryt`}</Button>
        </Group>
      </Flex>
    );
  }

  return (
    <Flex direction={'column'} w={'100%'}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <form>
          <Stack spacing={'lg'} p={0}>
            <TextInput label={t`Titel`} {...form.getInputProps('label.0.text')} maxLength={INPUT_MAX_LENGTH} />
            <Textarea label={t`Beskrivning`} {...form.getInputProps('description.0.text')} />
            {selectedElement.type === FormElementType.TypedTextBox && renderInputType()}
            {form.values.type === FormElementType.Repeater && (
              <>
                <TextInput label={t`Knapptext`} {...form.getInputProps('addButtonText.0.text')} maxLength={INPUT_MAX_LENGTH} />
                <Checkbox
                  pl={4}
                  style={{ display: 'flex' }}
                  label={t`Text endast på först raden`}
                  {...form.getInputProps('labelsOnFirstGroup', { type: 'checkbox' })}
                />
                <Checkbox
                  pl={4}
                  style={{ display: 'flex' }}
                  label={t`Summera nummerfält`}
                  {...form.getInputProps('sumNumberFields', { type: 'checkbox' })}
                />
              </>
            )}
            {ToolboxItems[selectedElement.type].canResize && (
              <Select
                label={t`Storlek`}
                data={[
                  { value: '12', label: '100%' },
                  { value: '9', label: '75%' },
                  { value: '8', label: '66%' },
                  { value: '6', label: '50%' },
                  { value: '4', label: '33%' },
                  { value: '3', label: '25%' },
                  { value: '2', label: '17%' },
                ]}
                {...form.getInputProps('sizeSelectValue')}
              />
            )}
            <Checkbox
              pl={4}
              style={{ display: 'flex' }}
              label={t`Obligatorisk`}
              {...form.getInputProps('required', { type: 'checkbox' })}
            />
            <Select 
              label={t`Titel och textposition`}
              data={[
                { value: 'left', label: 'Vänster' },
                { value: 'right', label: 'Höger' },
              ]}
              {...form.getInputProps('labelAndTextPosition')}
              value={form.values.labelAndTextPosition ?? 'left'}
            />
            {form.values.type === FormElementType.TextArea && (
              <SimpleGrid cols={2}>
                <div>
                  <NumberInput
                    min={1}
                    max={10}
                    defaultValue={1}
                    label="Min Rader"
                    {...form.getInputProps('minRows')}
                  />
                </div>
                <div>
                  <NumberInput
                    min={1}
                    max={10}
                    defaultValue={1}
                    label="Max rader"
                    {...form.getInputProps('maxRows')}
                  />
                </div>
              </SimpleGrid>
            )}

            {form.values.type === FormElementType.TextArea && (
              <NumberInput
                label={t`Max antal tecken`}
                description={t`Visar räknare för hur många tecken man får ha och hur många som utnyttjats (Max 10000 tecken)`}
                max={10000}
                min={0}
                hideControls
                {...form.getInputProps('maxCharCount')}
              />
            )}

            {(form.values.type === FormElementType.Dropdown ||
              form.values.type === FormElementType.CheckboxGroup ||
              form.values.type === FormElementType.RadioButtonGroup ||
              form.values.type === FormElementType.AmountsTable) &&
              renderOptions(form.values)}
            <Textarea
              label={t`Hjälptext`}
              autosize={true}
              minRows={2}
              maxRows={6}
              maxLength={1500}
              value={
                form.values.helpText
                  ? LanguageHandler.getTextByLanguage(form.values.helpText, LanguageCode.sv)
                  : ''
              }
              onChange={(event) => {
                const eventValue = event.currentTarget.value;
                if (eventValue) {
                  const newValue: TranslationItem = { language: LanguageCode.sv, text: eventValue };
                  form.setFieldValue('helpText', [newValue]);
                } else {
                  form.setFieldValue('helpText', null);
                }
              }}
            />
          </Stack>
        </form>
      </Box>
      <Group position={'right'} pt={'xl'}>
        <Button onClick={() => submit()}>{t`Spara`}</Button>
        <Button onClick={() => close()}>{t`Avbryt`}</Button>
      </Group>
    </Flex>
  );
};
