import { Stack, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { SearchResultListViewColumns } from './SearchResultListViewColumns';
import { FetchForReviewType } from '../../models/PagedResult';

export const SearchResultListView: React.FC = () => {
  const [searchResult, setSearchResult] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const { keyword } = useParams();

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      if (keyword) {
        setIsFetching(true);

        const result = await api.search(selectedOrganisation.id, keyword);
        setSearchResult(result);

        setIsFetching(false);
      }
    };

    if (selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation, keyword]);

  if (!searchResult) return <></>;

  return (
    <GrantMeContainer>
      <Stack spacing={0}>
        <GrantMeTitleContainer>
          <Title order={3}>Sökresultat för &quot;{keyword}&quot;</Title>
        </GrantMeTitleContainer>
        <LiitGrid
          data={searchResult.applications}
          columnInfo={SearchResultListViewColumns()}
          onRowClick={(id: string) => {
            navigate(`/applications/${id}?filterOnType=${FetchForReviewType.Search}`);
          }}
          highligthOnHoover
          fetchingData={isFetching}
        />
      </Stack>
    </GrantMeContainer>
  );
};
