import React, { useEffect, useState } from 'react';
import { Button, Stack, TextInput, Title, Text, Textarea } from '@mantine/core';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons';
import { useSession } from '../../hooks/useSession';
import { useApi } from '../../hooks/useApi';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { DisplayButtons } from '../../components/LiitGrid/DisplayButtons';
import { isMissing } from 'utilitype';
import { LiitDrawer } from '../../components/LiitDrawer/LiitDrawer';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { t } from 'ttag';
import { BankAccount } from '../../models/BankAccount';
import { useForm } from '@mantine/form';
import { LiitFormContainer } from '../../components/LiitFormContainer/LiitFormContainer';
import { showFailNotification, showSuccessNotification } from '../../utils/notificationHelper';
import { openConfirmModal, openContextModal } from '@mantine/modals';

export const SettingsBankAccountsView: React.FC = () => {
  const api = useApi();
  const [bankAccounts, setBankAccounts] = useState<BankAccount[] | undefined>(undefined);
  const { selectedOrganisation } = useSession();
  const [isFetching, setIsFetching] = useState(false);
  const [formOpened, setFormOpened] = useState<boolean>(false);

  const form = useForm<BankAccount>({
    initialValues: { id: '', name: '', description: '', iban: '', bic: '' },
    validate: {
      name: (value) => (value || value.length > 0 ? null : 'Ett namn måste anges'),
      bic: (value) =>
        /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/.test(value)
          ? null
          : 'Ett giltigt BIC måste anges',
      iban: (value) =>
        /[A-Z]{2,2}[0-9]{2,2}[a-zA-Z0-9]{1,30}/.test(value) ? null : 'Ett giltigt iban måste anges',
    },
  });

  const createBankAccount = async () => {
    form.reset();
    setFormOpened(true);
  };

  const refresh = async () => {
    if (!selectedOrganisation) {
      return;
    }

    setIsFetching(true);
    const result = await api.getBankAccounts(selectedOrganisation.id);
    setBankAccounts(result);
    setIsFetching(false);
  };

  const editBankAccount = async (id: string) => {
    if (!bankAccounts) {
      return;
    }

    const account = bankAccounts.find((x) => x.id === id);

    if (isMissing(account)) {
      return;
    }
    form.setValues(account);
    setFormOpened(true);
  };

  const save = async (values) => {
    const typeValues = values as BankAccount;
    if (!selectedOrganisation) {
      return;
    }

    const isNew = !values.id;

    const response = isNew
      ? await api.createBankAccount(selectedOrganisation.id, typeValues)
      : await api.updateBankAccount(selectedOrganisation.id, typeValues);

    if (response.ok) {
      await refresh();

      const title = isNew ? t`Bankkonto skapat` : t`Bankkonto uppdaterat`;
      const message = isNew
        ? t`Bankkonto ${typeValues.name} har skapats`
        : t`Bankkonto ${typeValues.name} har uppdaterats`;
      showSuccessNotification(title, message);
      setFormOpened(false);
      return;
    }

    //showFailNotification(t`Något gick fel`, t`Något gick fel vid sparning, var god försök senare`);
  };

  const deleteBankAccount = async (id: string) => {
    if (!selectedOrganisation) {
      return;
    }

    const response = await api.deleteBankAccount(selectedOrganisation.id, id);

    if (response.ok) {
      await refresh();

      const message = t`Bankkontot togs bort`;
      showSuccessNotification('', message);
      setFormOpened(false);
      return;
    }
  };

  const openConfirmDeleteBankAccountModel = (bankAccountId: string) => {
    return openConfirmModal({
      title: <Title order={3}>{t`Ta bort bankkonto`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>{t`Vill du verkligen ta bort bankkontot?`}</Text>
        </Stack>
      ),
      labels: { confirm: 'Ta bort', cancel: 'Avbryt' },
      onCancel: () => {},
      onConfirm: async () => {
        await deleteBankAccount(bankAccountId);
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await refresh();
    };

    fetchData();
  }, [selectedOrganisation]);

  const displayColumns: IDisplayColumn[] = [
    {
      displayName: 'Namn',
      propertyName: 'name',
      propertyType: PropertyType.Text,
    },
    {
      displayName: 'Description',
      propertyName: 'description',
      propertyType: PropertyType.Text,
    },
  ];

  const gridButtons: DisplayButtons[] = [
    {
      name: 'edit',
      icon: <IconEdit size={24} />,
      onClick: (id: string) => editBankAccount(id),
    },
    {
      name: 'remove',
      icon: <IconTrash size={24} />,
      onClick: (id: string) => openConfirmDeleteBankAccountModel(id),
    },
  ];

  return (
    <GrantMeContainer>
      <Stack spacing={0}>
        <GrantMeTitleContainer>
          <Title order={3}>Bankkonton</Title>
          <Button size={'sm'} onClick={() => createBankAccount()} leftIcon={<IconPlus />}>
            {t`Skapa bankkonto`}
          </Button>
        </GrantMeTitleContainer>
        <LiitGrid
          data={bankAccounts}
          columnInfo={displayColumns}
          displayButtons={gridButtons}
          fetchingData={isFetching}
          onRowClick={(id) => editBankAccount(id)}
        />
      </Stack>
      <LiitDrawer
        title={<Title order={2}>{form.values.id ? 'Redigera bankkonto' : 'Skapa bankkonto'}</Title>}
        opened={formOpened}
        size="xl"
        padding="xl"
        onClose={() => {
          setFormOpened(false);
        }}
        position="right"
        withCloseButton>
        <form
          onSubmit={form.onSubmit(async (values) => save(values))}
          style={{ width: '100%', display: 'flex' }}>
          <LiitFormContainer
            confirmText={t`Spara`}
            onCancel={() => {
              setFormOpened(false);
            }}>
            <Stack>
              <TextInput
                label={t`Namn`}
                {...form.getInputProps('name')}
                maxLength={255}
                withAsterisk
              />
              <Textarea
                label={t`Beskrivning`}
                {...form.getInputProps('description')}
                maxLength={1024}
              />
              <TextInput
                label={t`BIC`}
                {...form.getInputProps('bic')}
                maxLength={11}
                withAsterisk
              />
              <TextInput
                label={t`IBAN`}
                {...form.getInputProps('iban')}
                maxLength={34}
                withAsterisk
              />
            </Stack>
          </LiitFormContainer>
        </form>
      </LiitDrawer>
    </GrantMeContainer>
  );
};
