import { FocusTrap, Group, TextInput, Transition, Text, Modal } from '@mantine/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconSearch } from '@tabler/icons';
import { useSession } from '../../hooks/useSession';
import { Role } from '../../models/Role';

export const SearchInput: React.FC = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const [showInput, setShowInput] = useState(false);
  const { selectedOrganisation } = useSession();

  if (selectedOrganisation?.role !== Role.Administrator) return <></>;

  return (
    <Group>
      {showInput && (
        <FocusTrap active={showInput}>
          <TextInput
            variant="filled"
            placeholder="Diarienr eller sökande"
            onChange={(event) => setKeyword(event.target.value)}
            value={keyword}
            onBlur={() => setShowInput(false)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (keyword) {
                  navigate('/search/' + encodeURIComponent(keyword));
                  setKeyword('');
                }
                setShowInput(false);
              }
            }}
          />
        </FocusTrap>
      )}

      <IconSearch size={18} color={'white'} onClick={() => setShowInput(!showInput)} />
    </Group>
  );
};
